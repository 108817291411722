import React, {useContext} from "react";
import { BiLogInCircle } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

const LoginButton = () => {
  const navigate = useNavigate();

  const loginButtonPressed = () => {
    navigate("/Login");
  };

  return (
    <button
      className="flex items-center justify-center gap-1 text-white shadow font-bold text-xs rounded-full px-2 py-1 bg-lightBlue"
      onClick={loginButtonPressed}>
      Login
      <BiLogInCircle className="size-5" />
    </button>
  );
};

export default LoginButton;
